import * as React from 'react'
import { TextField } from '@material-ui/core'
import { Field, FieldProps, FieldRenderProps } from 'react-final-form'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

// interface ISingleLineTextFieldProps {
//   name: string;
//   label: string;
//   className: string;
//   variant?: 'standard';
// }

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      // marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      maxWidth: 350,
    },
  }),
)

export default function SingleLineTextField({ name, label, className, variant, ...restProps }: FieldProps<string, FieldRenderProps<string>>) {
  const classes = useStyles()

  return (
    <Field name={name} {...restProps}>
      {({ input, meta }) => (
        <TextField
          error={meta.touched && !!meta.error}
          label={label}
          id={name}
          helperText={meta.touched && meta.error}
          variant={variant}
          className={classes.textField}
          {...input}
        />
      )
      }
    </Field>
  )
}