import * as React from 'react'
import Layout from '../components/layout'
import { Router } from '@reach/router'
import EditBrewery from '../components/EditBrewery/EditBrewery'
import DetailedBrewery from '../components/DetailedBrewery/DetailedBrewery'
import { API, graphqlOperation } from 'aws-amplify'
import { updateBrewery } from '../graphql/mutations'

function EditBreweryRoute(props) {

  // const [brewery, setBrewery] = React.useState<IBrewery>()

  // React.useEffect(() => {
  //   API.graphql(graphqlOperation(getBrewery, {
  //     id: props.id,
  //   })).then(({ data: { getBrewery: breweryData } }) => setBrewery(breweryData))
  // }, [])

  const saveBrewery = (submittedBrewery) => API.graphql(graphqlOperation(updateBrewery, { input: submittedBrewery }))
  if (brewery) {
    return <EditBrewery brewery={brewery} onSave={saveBrewery}/>
  } else {
    return <h1>Loading</h1>
  }
}

export default function Brewery() {
  return (
    <Layout>
      <Router>
        <DetailedBrewery path="/brewery/:id"/>
        <EditBreweryRoute path="/brewery/:id/edit"/>
      </Router>
    </Layout>
  )
}
