import * as React from 'react'
import { Box, Button } from '@material-ui/core'
import { Comment as CommentIcon, Share as ShareIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
  },
  actionItem: {
    marginRight: '10px',
  },
})

export default function BreweryActionBar() {
  const classes = useStyles()
  return (
    <Box className={classes.actionBar}>
      <Button variant="contained" color="primary" className={classes.actionItem}>
        <CommentIcon/> Add Review</Button>
      <Button variant="contained" className={classes.actionItem}>
        <ShareIcon>add_circle</ShareIcon>Share
      </Button>
    </Box>
  )
}