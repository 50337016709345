import * as React from 'react'
import { Form } from 'react-final-form'
import { Button } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { IBrewery } from '../../models/brewery'
import CircularProgress from '@material-ui/core/CircularProgress'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import DateFnsUtils from '@date-io/dayjs'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import BusinessHourPicker from '../FormFields/BusinessHourPicker/BusinessHourPicker'
import WeekdayPicker from '../FormFields/WeekdayPicker/WeekdayPicker'
import SingleLineTextField from '../FormFields/SingleLineTextField/SingleLineTextField'
import { Link } from 'gatsby'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import Grid from '@material-ui/core/Grid'
import FormSectionTitle from '../../styled/FormSectionTitle'
import GoogleMapReact from 'google-map-react'
import AddressField from '../FormFields/AddressField/AddressField'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(5),
    },
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
      },
    },
    formSection: {
      marginTop: theme.spacing(5),
    },
    sectionTitle: {
      fontSize: '1.5rem',
    },
    textField: {
      // marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      maxWidth: 350,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    businessHoursRow: {
      display: 'flex',
    },
    buttonControl: {
      margin: theme.spacing(2),
    },
  }),
)

interface IEdtitBreweryProps {
  brewery: IBrewery;
  onSave: (brewery: IBrewery) => void;
}

export default function EditBrewery({ brewery, onSave }: IEdtitBreweryProps) {
  const classes = useStyles()
  const onSubmit = (values) => {
    const { createdAt, updatedAt, ...brewery } = values
    console.log('Submitting', brewery)
    return onSave(brewery)
  }

  const validate = (values) => {
    return undefined
  }

  return (
    <Paper className={classes.paper}>
      <Link to={`/brewery/${brewery.id}`}>&laquo; Back</Link>
      <Form
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
          getTime: (p) => {
            console.log(p)
          },
        }}
        validate={validate}
        initialValues={brewery}
        render={({
                   handleSubmit, submitting, form: {
            mutators: { push, pop },
          },
                 }) => (
          <form className={classes.root} onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <FormSectionTitle component={'h6'}>Business Information</FormSectionTitle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SingleLineTextField label={'Name'} name={'name'} className={classes.textField}/>
                <SingleLineTextField label={'Phone'} name={'phoneNumber'} className={classes.textField}/>
                <SingleLineTextField label={'Webpage'} name={'webpage'} className={classes.textField}/>
              </Grid>
              <Grid item xs={12} sm={6}>
                Logo
              </Grid>
              <Grid item xs={12}>
                <FormSectionTitle component={'h6'}>Business Address</FormSectionTitle>
              </Grid>
              <Grid item xs={12} sm={6}>
                <AddressField name={'address'}/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: process.env.GATSBY_REACT_APP_GOOGLE_API_KEY || '' }}
                  center={
                    {
                      lat: 0,
                      lng: 0,
                    }
                  }
                  defaultZoom={11}
                  // options={createMapOptions}
                  // onChange={onBoundsChange}
                  // onDrag={onDrag}
                >
                </GoogleMapReact>
              </Grid>
              <Grid item xs={12}>
                <FormSectionTitle component={'h6'}>Business Hours
                  <IconButton
                    aria-label="delete"
                    color={'secondary'}
                    onClick={() => push('businessHours', { from: new Date(), to: new Date() })}
                  >
                    <AddIcon fontSize="large" color={'secondary'}/>
                  </IconButton>
                </FormSectionTitle>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <FieldArray name="businessHours">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <div key={name} className={classes.businessHoursRow}>
                          <WeekdayPicker name={`${name}.day`} label={'Weekday'} className={classes.formControl}/>
                          <BusinessHourPicker name={`${name}.from`} label={'From'}/>
                          <BusinessHourPicker name={`${name}.to`} label={'To'}/>
                          <div className={classes.buttonControl}>
                            <IconButton aria-label="delete" onClick={() => fields.remove(index)}>
                              <DeleteIcon fontSize="default" color={'secondary'}/>
                            </IconButton>
                          </div>
                        </div>
                      ))
                    }
                  </FieldArray>
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Button variant="contained" color="primary" disabled={submitting} type={'submit'}>
              {submitting ? <CircularProgress/> : 'Save'}
            </Button>
          </form>
        )}
      />
    </Paper>
  )
}
