export enum Weekdays {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday'
}

export interface IBusinessHours {
  day: Weekdays,
  from: string,
  to: string
}

export interface ICoordinates {
  lat: number
  lon: number
}

export interface IAddress {
  line1: string
  line2: string
  city: string
  state: string
  country: string
  postalcode: string
}

export interface IBrewery {
  id: string;
  name: string;
  phoneNumber?: string
  webpage?: string
  coordinates?: ICoordinates
  businessHours: IBusinessHours[]
  address: IAddress
}

//
// export interface IBrewery {
//   id: number
//   name: string
//   phone: string
//   website_url: string
//   brewery_type: string
//   latitude: string
//   longitude: string
//   street: string
//   address_2: string | null
//   address_3: string | null
//   city: string
//   country: string
//   county_province: string | null
//   postal_code: string
//   state: string
// }