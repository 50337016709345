import { useUser } from '../../Looking4Beer/Users/Services/UserContext'
import * as React from 'react'
import BeerName from '../../styled/BeerName'
import Section from '../../styled/Section'
import Rating from '../../styled/Rating'
import BreweryActionBar from '../BreweryActionBar/BreweryActionBar'
import { Link } from 'gatsby'
import { useSelector } from 'react-redux'

//TODO: Load brewery if going directly
export default function DetailedBrewery(props) {
  const { user } = useUser()
  const brewery = useSelector(({ breweries: { data } }) => (data.find(b => b.id === props.id)))

  if (!brewery) {
    return <div>Loading...</div>
  }
  return (
    <>
      <BeerName>{brewery.name}</BeerName>
      <Section>
        <Rating rating={3.5} count={102}/>
      </Section>
      <Section>
        {/*<ContactInformation*/}
        {/*  address={''}*/}
        {/*  businessHours={brewery.businessHours}*/}
        {/*  phoneNumber={brewery.phoneNumber}*/}
        {/*  url={brewery.webpage}*/}
        {/*/>*/}
      </Section>
      <Section>
        <BreweryActionBar/>
      </Section>
      {/*<DirectionAndHours businessHours={brewery.businessHours}/>*/}


      {/*Telephone*/}
      {/*Website*/}
      {/*<Description />*/}
      {/*<Reviews />*/}
      {user && <Link to={`/brewery/${props.id}/edit`}>Edit</Link>}
    </>
  )
}
