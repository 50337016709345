import * as React from 'react'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import { Weekdays } from '../../../models/brewery'
import MenuItem from '@material-ui/core/MenuItem'
import { Field } from 'react-final-form'

interface IWeekdayPickerProps {
  name: string;
  label: string;
  className: string;
}

export default function WeekdayPicker({ name, className, label }: IWeekdayPickerProps) {
  return (<Field name={name}>
      {({ input, meta }) => (
        <FormControl className={className}>
          <InputLabel id={`${name}-label`}>{label}</InputLabel>
          <Select
            labelId={`${name}-label`}
            {...input}
          >
            {Object.keys(Weekdays)
              .map(key => <MenuItem key={key} value={key}>{Weekdays[key]}</MenuItem>,
              )
            }
          </Select>
        </FormControl>
      )}
    </Field>
  )
}