import { styled } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { ComponentType } from 'react'

interface IFormSectionTitleProps {
  component: string
}

const FormSectionTitle: ComponentType<IFormSectionTitleProps> = styled(Typography)(({
                                                                                      theme,
                                                                                    }) => ({
  fontSize: '1.5rem',
  marginTop: theme.spacing(5),
}))

export default FormSectionTitle