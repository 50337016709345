import * as React from 'react'
import MUIRating from '@material-ui/lab/Rating'
import { Box, Typography } from '@material-ui/core'

interface IRatingProps {
  rating: number;
  count: number;
}

export default function Rating({ rating, count }: IRatingProps) {
  return (
    <Box>
      <MUIRating name="read-only" value={rating} readOnly/>
      <Typography>{count} Reviews</Typography>
    </Box>
  )
}