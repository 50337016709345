import * as React from 'react'
import { KeyboardTimePicker } from '@material-ui/pickers'
import { Field } from 'react-final-form'

export default function BusinessHourPicker({ name, label }: { name: string, label: string }) {
  return (<Field
    name={name}
    parse={(value, name) => {
      return {
        hour: value.format('HH'),
        minutes: value.format('mm'),
      }
    }}
    format={(value, name) => {
      const now = (new Date())
      if (value && value.hour !== undefined && value.minutes !== undefined) {
        return now.setHours(value.hour, value.minutes)
      }
      return now
    }}
  >
    {({ input }) => (
      <KeyboardTimePicker
        id={name}
        margin="normal"
        label={label}
        KeyboardButtonProps={{
          'aria-label': 'change time',
        }}
        {...input}
      />
    )
    }
  </Field>)
}