import * as React from 'react'
import SingleLineTextField from '../SingleLineTextField/SingleLineTextField'
import { FieldProps, FieldRenderProps } from 'react-final-form'
import { IAddress } from '../../../models/brewery'

const isRequired = (fieldName: string) => (value?: string) => {
  console.log(value)
  if (value === undefined) {
    return `${fieldName} is required`
  }
  return undefined
}

export default function AddressField({ name, validate }: FieldProps<IAddress, FieldRenderProps<IAddress>>) {
  return (
    <>
      <SingleLineTextField label={'Address 1'} name={`${name}.line1`} validate={isRequired('Address 1')}/>
      <SingleLineTextField label={'Address 2'} name={`${name}.line2`}/>
      <SingleLineTextField label={'City'} name={`${name}.city`} validate={isRequired('City')}/>
      <SingleLineTextField label={'State'} name={`${name}.state`} validate={isRequired('State')}/>
      <SingleLineTextField label={'Postal Code'} name={`${name}.postalcode`} validate={isRequired('Postal Code')}/>
    </>
  )
}