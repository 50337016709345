/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBrewery = /* GraphQL */ `
  mutation CreateBrewery(
    $input: CreateBreweryInput!
    $condition: ModelBreweryConditionInput
  ) {
    createBrewery(input: $input, condition: $condition) {
      id
      name
      type
      phoneNumber
      webpage
      coordinates {
        lat
        lon
      }
      businessHours {
        day
        from {
          hour
          minutes
        }
        to {
          hour
          minutes
        }
      }
      address {
        line1
        line2
        line3
        city
        state
        country
        postalcode
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBrewery = /* GraphQL */ `
  mutation UpdateBrewery(
    $input: UpdateBreweryInput!
    $condition: ModelBreweryConditionInput
  ) {
    updateBrewery(input: $input, condition: $condition) {
      id
      name
      type
      phoneNumber
      webpage
      coordinates {
        lat
        lon
      }
      businessHours {
        day
        from {
          hour
          minutes
        }
        to {
          hour
          minutes
        }
      }
      address {
        line1
        line2
        line3
        city
        state
        country
        postalcode
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBrewery = /* GraphQL */ `
  mutation DeleteBrewery(
    $input: DeleteBreweryInput!
    $condition: ModelBreweryConditionInput
  ) {
    deleteBrewery(input: $input, condition: $condition) {
      id
      name
      type
      phoneNumber
      webpage
      coordinates {
        lat
        lon
      }
      businessHours {
        day
        from {
          hour
          minutes
        }
        to {
          hour
          minutes
        }
      }
      address {
        line1
        line2
        line3
        city
        state
        country
        postalcode
      }
      createdAt
      updatedAt
    }
  }
`;
